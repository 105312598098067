import React from "react"
import Button from "../components/Button"
import AniLink from "gatsby-plugin-transition-link/AniLink";

let items = [{
	icon : 'Solutions',
	title: 'Soluciones a la medida',
	description: 'Cuando lo existente nocumple los objetivos, nos volcamos a crear una solución a la medida, escalable multi plataforma y de costo adecuado.',
	color: "bg-blue-900"
},{
	icon : 'Tools',
	title: 'Herramientas de negocio y movilidad',
	description: 'Hemos desarrollado soluciones para procesos de venta, localización, captura de datos, capacitaciones, encuestas de clima laboral, etc.',
	color: "bg-orange-100"
},{
	icon : 'Data',
	title: 'Big Data & Business Intelligence',
	description: 'Contamos con expertos en  inteligencia de negocios y BigData, con los que podrás mejorar los procesos y la toma de deciones en tu empresa.',
	color: "bg-purple-200"
},{
	icon : 'Outsourcing',
	title: 'Outsourcing de Recursos',
	description: 'Actualmente, brindamos el servicio de outsourcing de recursos en el área de desarrollo, operaciones y big data.',
	color: "bg-purple-900"
},{
	icon : 'Cloud',
	title: 'Servicios cloud',
	description: 'Migración y seguridad en la nube así como arquitecturas de servidores que ajustan automáticamente a  tu capacidad acorde a la demanda (autoescalado).',
	color: "bg-blue-25"
},{
	icon : 'Design',
	title: 'Diseño de productos',
	description: 'Construimos y diseñamos interfaces con una experiencia agradable para el usuario, además que nuestras soluciones son adaptables para web y móvil.',
	color: "bg-green-900"
},{
	icon : 'Marketing',
	title: 'Marketing digital',
	description: 'Vamos más allá después de terminar un producto, proveemos landing pages, campañas publicitarias en redes, producción de contenido, entre otros.',
	color: "bg-orange-400"
},]

// const goToForm = () => {
// 	console.log("hola mundo")
// }

export default function about(props) {
    return (
		<div>
			<div className="bg-services-header relative h-96 bg-fixed bg-center bg-no-repeat bg-cover flex justify-center items-center mb-24">
				<div className="h-full w-full absolute bg-blue-900 z-0 opacity-50 group-hover:opacity-0 transition duration-100 ease-in-out" />
				<div className="container mx-auto relative text-center">
					<h1 className="text-6xl font-bold text-white" >Nuestros servicios</h1>
				</div>
			</div>
			<div className="container mx-auto px-12 flex flex-col">
				<div className="text-center mb-24 lg:w-1/2 w-full self-center">
					<h2 className="text-gray font-bold text-4xl mb-12">
						Proveemos servicios de <span className="text-blue-900 md:text-gray">clase mundial</span> desde el 2011
					</h2>
					<span className="text-gray-400 text-base">
						Contamos con un equipo de desarrolladores y diseñadores experimentados, listos para ayudarte a construir herramientas ágiles para tu negocio.
					</span>
				</div>
				<div className="grid md:grid-cols-2 grid-cols-1 lg:gap-48 gap-24 px-12 lg:px-48">
					{items.map( (item, i) => 
						<div className={`col-span-1 ${item.color} md:bg-white p-12 md:p-0 rounded-xl`} key={i}>
							<img src={"/images/" + item.icon + ".svg"} alt={item.title} className="white-filter md:no-filter" />
							<h3 className="md:text-gray text-white text-xl font-bold my-8">
								{item.title}
							</h3>
							<p className="md:text-gray-400 text-white text-base">
								{item.description}
							</p>
						</div>
					)}
				</div>
				<div className="flex flex-col mt-24 items-center justify-center rounded-lg bg-blue-900 shadow-xl p-12">
						<h2 className="text-white font-extrabold text-4xl mb-12">
							¿Te interesa mejorar la productividad de tu empresa?
						</h2>
						<AniLink paintDrip hex="#FFFFFF" to="/#mainForm" activeClassName="font-bold text-blue">
                            <Button classes="px-8 py-4 bg-white font-bold text-green-900 rounded-lg">
                                Contáctanos ahora
                            </Button>
                        </AniLink>
				</div>
			</div>
		</div>
    )
}